import { Account } from '@/backend/models'
import { ErrorUnauthorized } from '@/lib/requests'
import * as request from '@/backend/requests'
import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useBackendSessionStore = defineStore('backendSession', () => {
  const account = ref(undefined as Account | null | undefined)
  const error = ref(null)

  async function prepare(ctx: any) {
    if (account.value !== undefined) {
      return
    }

    try {
      account.value = await new request.AccountShow().setup(ctx).perform()
    } catch (e) {
      if (e instanceof ErrorUnauthorized) {
        account.value = null
        return
      }
      throw e
    }
  }

  function allow() {
    return function(action: string, resource: any) {
      if (account.value == null || account.value == undefined) {
        return false
      }

      return account.value.allow(action, resource)
    }
  }

  function clear() {
    account.value = null
  }

  return { account, error, prepare, allow, clear }
})
